.arrow-right {
  svg {
    font-size: 80px;
  }
}

@media screen and (max-width: 767px) {
  .arrow-right {
    svg {
      font-size: 24px;
    }
  }
}
