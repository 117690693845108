.rangePicker {
  width: 100%;
  min-height: 2.625rem;
  border-radius: 0.25rem;
  cursor: pointer;
  :global {
    .ant-picker-input {
      width: 90px !important;
    }
    .ant-picker-active-bar {
      display: none;
    }
    .ant-picker-suffix {
      width: calc(100% - 200px);
      text-align: right;
    }
  }
}

.rangePicker:hover {
  border-color: #cbe0e6;
}

.footer button {
  width: 8.5rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  color: #7a7984;
  border-color: #aaadb9;
  border-radius: unset;
  &:hover {
    // color: #299acb;
    color: #46b0c6;
    background: transparent;
    border-color: #aaadb9;
  }
  &.active {
    color: #ffffff;
    // background: #299acb;
    background: #46b0c6;
    border-color: #aaadb9;
  }
}

.footer :not(:last-child) {
  border-right: 0;
}

.footer :first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.footer :last-child {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  // border-right: 1px solid #aaadb9;
}

// :global {
//   .ant-picker-dropdown {
//     margin-top: -140px !important;
//     margin-left: -35px !important;
//   }
//   .ant-dropdown.ant-dropdown-placement-bottomLeft {
//     display: none;
//   }
// }

@media screen and (max-width: 767px) {
  .footer button {
    width: auto;
    display: flex;
    flex: 1;
    padding-left: 0;
    padding-right: 0;
  }
}
