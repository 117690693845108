:global {
  .ant-modal-content {
    background-color: #f2f6f7;
  }
  .ant-modal-header {
    background-color: #f2f6f7;
  }
}

.row {
  display: flex;
  gap: 48px;
  padding-bottom: 48px;
  margin-bottom: 48px;
  border-bottom: 1px solid #9ca3af33;
}

.label {
  width: 383px;
}

.guest-container {
  max-height: 650px;
  overflow: auto;
}

@media screen and (max-width: 767px) {
  .label {
    width: 52px;
  }
  .row {
    gap: 24px;
    padding-bottom: 24px;
    margin-bottom: 24px;
  }
  .item {
    max-width: calc(100dvw - 222px);
  }

  .guest-container {
    max-height: 500px;
  }
}
