.container {
  overflow: hidden;
}

:global {
  .user-nav {
    max-width: 300px;

    .ant-dropdown-menu-item {
      padding: 0px;

      &:hover {
        background-color: transparent;
      }
    }
    &.ant-dropdown-menu {
      max-height: unset;
      border-radius: 8px;
      overflow: hidden;
    }
    &.ant-dropdown-trigger {
      transition: background-color 1s;
      cursor: pointer;
    }
    &.ant-dropdown-trigger:hover,
    &.ant-dropdown-trigger.ant-dropdown-open {
      //background: #f5c83e;
    }
  }
}
.borderBottom {
  border-bottom: 0.4px solid #cbe0e6;
}
