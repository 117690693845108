.modal {
  :global {
    .ant-modal-header,
    .ant-modal-content {
      background-color: #f2f6f7;
    }
  }
}

.container-content {
  max-height: 80vh;
  overflow: hidden;
}

.p-err {
  font-size: 10px;
  line-height: 18px;
  color: #EA4241;
  margin-top: 14px;
}